export default {
  fonts: {
    main: "muli",
  },
  colors: {
    main1: "#ff8800",
    light1: "#ffffff",
    dark1: "brown",
  },
}
